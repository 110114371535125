<template>
  <!-- Add NavBar -->
  <div
      :class="{
    'loading-blur': isLoading,
      }"
      class="">
    <router-view/>
    <l-loading></l-loading>
  </div>
</template>
<script>
import LLoading from "@/components/custom/loading/LLoading";
import {mapGetters} from "vuex";
export default {
  components: { LLoading },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  created() {
    this.$store.dispatch("checkLoginStatus");
    this.$store.dispatch("getRegistrationDropDowns");
    this.$store.dispatch("getAffiliatedUniversities");
    this.$store.dispatch("getDistrictsData");
    this.$store.dispatch("getZonesData");
    this.$store.dispatch("getCategoriesData");
    this.$store.dispatch("getFinancialYearData");
  },
  methods: {
    changeLocale() {
      const locale = localStorage.getItem("lang");
      this.$i18n.locale = locale || "en";
    },
  },
  mounted() {
    this.changeLocale();
    setTimeout(() => {
      this.changeLocale();
    }, 1000);
  }
};
</script>
<style lang="scss">
</style>
