import { Module, ActionTree } from "vuex";
import eoiAuth from "@/http/eoiAuth";

const actions: ActionTree<any, any> = {
    

      

};
const upscUserRegistrations: Module<any, any> = {
  
};
export default upscUserRegistrations;