import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import moment from "moment";

const actions: ActionTree<any, any> = {
  getCollegeFaculties({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/faculties/`, { params: data });
        commit('setCollegeFaculty', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchSingleCollegeFaculty({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/faculty/`, { params: data });
        commit('setCollegeFaculty', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addCollegeFaculty({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/faculty/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegeFaculty({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(`/api/v1/college/faculty/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  deleteCollegeFaculty({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.delete(`/api/v1/college/faculty/`, {data: formData});

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  addCollegeFacultyFeedback({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/courses/faculty/feedback/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  addStudentFeedback({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/courses/student/feedback/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getFacultyFeedbackQuestions({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/courses/feedback_questions/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },


  getStudentFeedbackQuestions({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/courses/student_feedback_questions/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },


  

  getFDPFeedbacks1({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      // commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/courses/faculty_feedbacks/`, { params: data });
       
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getFDPFeedbackOverviews({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      // commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/courses/faculty_feedback_overview/`, { params: data });
       
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendFacultyInvite({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`api/v1/college/sending_mail_to_faculty/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const collegeFacultyStore: Module<any, any> = {
  state: () => ({
    collegeFaculty: [],
  }),
  mutations: {
    setCollegeFaculty(state, data: any) {
      state.collegeFaculty = data;
    },
  },
  actions,
  getters: {
    collegeFaculty(state) {
        return state.collegeFaculty;
    }
  },
};

export default collegeFacultyStore;
