import moment from "moment";
import {baseUrlNoEndSlash} from "@/http/global_urls";

function createFilters(app: any) {
    app.config.globalProperties.$filters = {
        addBaseUrl(value: any) {
            if (value.startsWith("http")) {
                return value;
            }
            return baseUrlNoEndSlash + value;
        },
        durationConvert(value: any) {
            const num = value / 60;
            const hours = (num / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if(rhours > 0) {
                return rhours + " h " + rminutes + " m";
            } else {
                return rminutes + " m";
            }
        },
        niceDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("h:mm a, Do MMMM YYYY");
            } catch (e) {
                return "NA";
            }
        },
        niceDateFormat2(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("Do MMMM YYYY, h:mm a");
            } catch (e) {
                return "NA";
            }
        },
        formalDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("DD-MM-YYYY");
            } catch (e) {
                return "NA";
            }
        },
        niceOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("Do MMMM YYYY");
            } catch (e) {
                return "NA";
            }
        },
        serverOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("YYYY-MM-DD");
            } catch (e) {
                return "NA";
            }
        },
        floatFormat(value: any) {
            try {
                if(value == null) {
                    return "-";
                }
                return parseFloat(value).toFixed(2);
            } catch (e) {
                return "NA";
            }
        },
        numberFormatToINR(value: any) {
            if (!value) {
                return value;
            }
            return Intl.NumberFormat('hi-IN', {style: 'currency', currency: 'INR'}).format(value);
        },
        getManagementType(value: any) {
            if (!value) {
                return "-";
            }
            // @ts-ignore
            const data = JSON.parse(localStorage.getItem('management_types'));
            if (data) {
                return data[value.toString()];
            }
        },
        getCollegeType(value: any) {
            if (!value) {
                return "-";
            }
            // @ts-ignore
            const data = JSON.parse(localStorage.getItem('college_types'));
            if (data) {
                return data[value.toString()];
            }
        }
    };
}

export default createFilters;
