import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "base" */ "../layouts/NavBarLayout.vue"),
    children: [
      // {
      //   path: "/",
      //   name: 'Home',
      //   meta: { },
      //   component: () => import(/* webpackChunkName: "base" */ '../views/ComingSoon.vue'),
      // },
      {
        path: "/",
        name: "Home",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "base" */ "../views/CheckingPage.vue"),
      },
      {
        path: "/admin/generate_certificate/",
        name: "GenerateCertificate",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/certificate/GenerateCertificate.vue"
          ),
      },
      // Admin Endpoints Start
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges',
      //   redirect: '/admin/colleges/invite',
      //   meta: { requiresAuth: true },
      // },
      // {
      //   path: '/admin/colleges/invite',
      //   name: 'AdminColleges',
      //   meta: { requiresAuth: true },
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminColleges.vue')
      // },
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminDashboard.vue"
          ),
      },
      {
        path: "/admin/courseSelections",
        name: "StudentCourseSelectionsDetails",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/admin/students/AdminStudentsCourseSelection.vue"
          ),
      },
      {
        path: "/admin/profile",
        name: "AdminProfilePage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/profile/AdminProfilePage.vue"
          ),
      },
      {
        path: "/admin/colleges/upload",
        name: "AdminCollegesCSVUpload",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/InviteCollegesUploadPage.vue"
          ),
      },
      {
        path: "/admin/colleges/bank-details",
        name: "AdminCollegesBankDetails",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminBankDetailsColleges.vue"
          ),
      },
      {
        path: "/admin/colleges/invited",
        name: "AdminInvitedColleges",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminInvitedColleges.vue"
          ),
      },
      {
        path: "/admin/faculties/mapping",
        name: "FacultiesFDPMapping",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/faculties/FacultiesFDPMapping.vue"
          ),
      },
      {
        path: "/admin/faculties/feedbacks",
        name: "facultyFeedbacks",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/faculties/FacultiesFDPFeedbacks.vue"
          ),
      },
      {
        path: "/admin/colleges/",
        name: "AdminColleges",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminRegisteredColleges.vue"
          ),
      },
      {
        path: "/admin/users/",
        name: "AdminUsers",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminUsers.vue"
          ),
      },
      {
        path: "/coaching/Competitive_Exams_Reports/",
        name: "Competitive Exams",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/coaching/Competitive_Exams_Reports.vue"
          ),
      },

      {
        path: "/landing/landing_website/",
        name: "Landing",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/landing/Landing_Website.vue"
          ),
      },
      {
        path: "/gallery/admin/nm/add/",
        name: "gallery_admin",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/landing/Gallery/index.vue"
          ),
      },
      {
        path: "/gallery/admin/nm/images/",
        name: "gallery_images",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/landing/Gallery/gallery.vue"
          ),
      },

      {
        path: "/admin/college/:collegeId",
        name: "AdminCollegeDetails",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminCollegeDetails.vue"
          ),
      },
      {
        path: "/admin/students",
        name: "AdminStudents",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminRegisteredStudents.vue"
          ),
      },
      {
        path: "/admin/students/upload",
        name: "AdminStudentsUploadPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/admin/students/AdminStudentsUploadPage.vue"
          ),
      },
      {
        path: "/admin/students/upload/history",
        name: "AdminStudentUploadHistory",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/admin/students/AdminStudentUploadHistory.vue"
          ),
      },
      {
        path: "/admin/students/upload/detail",
        name: "AdminStudentUploadDetailPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/admin/students/AdminStudentUploadDetailPage.vue"
          ),
      },
      {
        path: "/admin/student/:studentId",
        name: "AdminStudentDetailsPage",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminStudentDetailsPage.vue"
          ),
      },
      {
        path: "/admin/placements/overview-report/",
        name: "AdminPlacementsOverviewReport",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/placements/AdminPlacementsOverviewReport.vue"
          ),
      },
      {
        path: "/admin/placements/download-report/",
        name: "AdminDownloadPlacementReports",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/placements/DownloadPlacementReports.vue"
          ),
      },
      {
        path: "/student/ambassador/nomination/reports",
        name: "StudentAmbassadorNominationReports",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/student/student_nomination/Student_Ambassador_Reports.vue"
          ),
      },
      {
        path: "/pm/placements/download-report/",
        name: "PMDownloadPlacementReports",
        props: true,
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/placements/DownloadPlacementReports.vue"
          ),
      },
      {
        path: "/student_ambassador_nomination/district/reports",
        name: "StudentAmbassadorNominationDistrictReports",
        props: true,
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/student/student_nomination/Student_Ambassador_District_Reports.vue"
          ),
      },
      {
        path: "/student/ambassador/nomination_reports",
        name: "StudentAmbassadorNominationSubAdminReports",
        props: true,
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/student/student_nomination/Student_Ambassador_Reports.vue"
          ),
      },
      {
        path: "/subadmin/placements/download-report/",
        name: "SADownloadPlacementReports",
        props: true,
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/placements/DownloadPlacementReports.vue"
          ),
      },
      {
        path: "/communication/schedule_email/",
        name: "ScheduleEmail",
        props: true,
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/communications/CommunicationsConfiguration.vue"
          ),
      },
      {
        path: "/admin/placements/districts-report/",
        name: "AdminPlacementsDistrictOverviewReport",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/placements/AdminPlacementsDistrictOverviewReport.vue"
          ),
      },
      {
        path: "/admin/courses/",
        name: "AdminCourses",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminCoursesPage.vue"
          ),
      },
      {
        path: "/admin/students/passout-validate",
        name: "StudentValidateProvisionalCertificate",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/validate/StudentValidateProvisionalCertificate.vue"
          ),
      },
      {
        path: "/admin/industries/",
        name: "AdminIndustries",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/industries/IndustriesListPage.vue"
          ),
      },
      {
        path: "/admin/oversease_report/",
        name: "OverSeasReports",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Seminar/OverSeasReports.vue"
          ),
      },
      {
        path: "/admin/internApprove/:id",
        name: "InternApprove",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/industries/InternApprovePage.vue"
          ),
      },
      {
        path: "/admin/intern/reports",
        name: "InternReportPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/industries/InternReportPage.vue"
          ),
      },
      {
        path: "/admin/trainingPartners/",
        name: "TrainingPartners",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/trainings/TrainingPartners.vue"
          ),
      },
      {
        path: "/admin/tp/logo",
        name: "TrainingPartnersLogo",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/trainings/TrainingPartnersLogo.vue"
          ),
      },
      {
        path: "/admin/job-fair/job-fair-listing/",
        name: "AdminIndustryJobFairListing",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobFair/IndustryJobFairListing.vue"
          ),
      },
      {
        path: "/industries/job-fair/assign/:jobFairId?",
        name: "IndustryJobFairAssign",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobFair/IndustryJobFairAssignPage.vue"
          ),
      },
      {
        path: "/industries/job-fair/add-edit/:jobFairId?",
        name: "IndustryJobFairAddEdit",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobFair/IndustryJobFairAddEditPage.vue"
          ),
      },
      {
        path: "/admin/mandatory-courses",
        name: "AdminAllocateMandatoryCoursesPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/mandatory-courses/AdminAllocateMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/admin/college/dashboard/:collegeType",
        name: "CollegeDashboardPage",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/dashboard/CollegeDashboardPage.vue"
          ),
      },
      {
        path: "/admin/reports/mandatory-courses/overview",
        name: "MandatoryCoursesReportOverview",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/mandatory-courses/MandatoryCoursesReportOverview.vue"
          ),
      },
      {
        path: "/admin/reports/mandatory-course/students",
        name: "MandatoryCourseStudentsReport",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/mandatory-courses/MandatoryCourseStudentsReport.vue"
          ),
      },
      {
        path: "/admin/lms/clients",
        name: "ListAPIClientsPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/api-client/ListAPIClientsPage.vue"
          ),
      },

      // {
      //   path: '/admin/eoi',
      //   name: 'EOIListPage',
      //   meta: { requiresAuth: true, role: "admin", },
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/eoi/EOIListPage.vue')
      // },

      {
        path: "/admin/eoi",
        name: "nmEoi",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/eoi/nmEoi.vue"
          ),
      },
      {
        path: "/admin/sigaram_thodu_eoi",
        name: "SigaramThoduEoi",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/eoi/sigaram_thodu/sigaramThoduEoi.vue"
          ),
      },
      {
        path: "/admin/new_eoi",
        name: "EOIListAdminPage2",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/eoi/EOIListPage2.vue"
          ),
      },
      {
        path: "/admin/financial_eoi",
        name: "EOIListPage2",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/eoi/nmfs/financial_reports.vue"
          ),
      },
      {
        path: "/admin/eoi/:eoiId",
        name: "EOIDetailsPage",
        props: true,
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/eoi/EOIDetailsPage.vue"
          ),
      },

      {
        path: "/admin/assessment/coursera",
        name: "CourseraAssessmentUploadHistory",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/CourseraAssessmentUploadHistory.vue"
          ),
      },

      // {
      //   path: '/admin/assessment/enrolledReports',
      //   name: 'EnrolledReports',
      //   meta: { requiresAuth: true, role: "admin", },
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/assessments/enrolledReports.vue')
      // },

      {
        path: "/admin/assessment/psychometric-reports",
        name: "PsychometricReports",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/mandatory-courses/PsychometricReports.vue"
          ),
      },

      {
        path: "/admin/assessment/enrolledReports",
        name: "EnrolledReports",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/enrolledReports.vue"
          ),
      },

      {
        path: "/admin/assessment/coursera/upload",
        name: "CourseraAssessmentUploadPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/CourseraAssessmentUploadPage.vue"
          ),
      },
      {
        path: "/admin/assessment/microsoft",
        name: "MicrosoftAssessmentUploadHistory",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/MicrosoftAssessmentUploadHistory.vue"
          ),
      },
      {
        path: "/admin/assessment/microsoft/upload",
        name: "MicrosoftAssessmentUploadPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/MicrosoftAssessmentUploadPage.vue"
          ),
      },

      {
        path: "/admin/assessment/infosys",
        name: "InfosysAssessmentUploadHistory",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/InfosysAssessmentUploadHistory.vue"
          ),
      },
      {
        path: "/admin/assessment/infosys/upload",
        name: "InfosysAssessmentUploadPage",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/assessments/InfosysAssessmentUploadPage.vue"
          ),
      },
      {
        path: "/admin/communications-configuration",
        name: "CommunicationsConfigurations",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/communications/CommunicationsConfiguration.vue"
          ),
      },
      {
        path: "/admin/industries/student-college-report",
        name: "StudentAndCollegeWiseReport",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/internships/StudentAndCollegeWiseReport.vue"
          ),
      },
      {
        path: "/admin/college-internship-allocation/:intern/:industryid",
        name: "CollegeInternshipAllocation",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/internships/CollegeInternshipAllocation.vue"
          ),
      },
      {
        path: "/admin/multiplemandatorycourse/",
        name: "AdminMultipleMandatoryCourses",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminMultipleMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/admin/intern/reports",
        name: "Intern Reports",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminMultipleMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/admin/course-content/",
        name: "AdminCourseContent",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/course/CourseContent.vue"
          ),
      },
      {
        path: "/admin/change-spoc",
        name: "Spoc Details",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/pm/PmChangeSpocApprove.vue"
          ),
      },
      {
        path: '/admin/courses/course-creation',
        name: 'CourseCreation',
        meta: { requiresAuth: true, role: "admin", },
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/courses/CourseCreation.vue')
      },

      // Admin Endpoints End

      // District Endpoints Start
      {
        path: "/district/dashboard",
        name: "DistrictDashboard",
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictDashboard.vue"
          ),
      },
      {
        path: "/district/enrollments",
        name: "DistrictEnrollments",
        // props: (route) => ({ collegeId: route.params.collegeId || null }),
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictEnrollments.vue"
          ),
      },
      {
        path: "/district/assessments",
        name: "DistrictAssessments",
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictEnrollments.vue"
          ),
      },
      {
        path: "/district/reports",
        name: "DistrictReports",
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictReport.vue"
          ),
      },
      {
        path: "/district/college",
        name: "DistrictCollege",
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictCollege.vue"
          ),
      },
      {
        path: "/district/college/current_status",
        name: "DistrictCollegeStatus",
        meta: { requiresAuth: true, role: "district" },
        component: () =>
          import(
            /* webpackChunkName: "district" */ "../views/district/DistrictCollegeStatus.vue"
          ),
      },

      // District Endpoints End

      // pd endpoints start
      {
        path: "/pd/dashboard",
        name: "PdDashboard",
        meta: { requiresAuth: true, role: "pd" },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/pd/PdDashboard.vue"),
      },
      //pd endpoints end

      //Pm Endpoints Start
      {
        path: "/pm/dashboard",
        name: "PmDashboard",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/pm/PmDashboard.vue"),
      },
      {
        path: "/pm/enrollments",
        name: "PmEnrollments",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/pm/PmEnrollments.vue"
          ),
      },
      {
        path: "/pm/assessments",
        name: "PmAssessment",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/pm/PmAssessment.vue"
          ),
      },
      {
        path: "/pm/bank-details",
        name: "PmBankDetailsColleges",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/pm/PmBankDetailsCollege.vue"
          ),
      },
      {
        path: "/pm/change-spoc",
        name: "PmChangeSpoc",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/pm/PmChangeSpocApprove.vue"
          ),
      },
      {
        path: "/pm/reports",
        name: "PmReports",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(/* webpackChunkName: "district" */ "../views/pm/PmReport.vue"),
      },
      {
        path: "/pm/multiplemandatorycourse/",
        name: "PMAdminMultipleMandatoryCourses",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminMultipleMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/pm/course-content/",
        name: "PmCourseContent",
        meta: { requiresAuth: true, role: "pm" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/course/CourseContent.vue"
          ),
      },
      //Pm Endpoints End

      //University Endpoints Start
      {
        path: "/university/dashboard",
        name: "UniversityDashboard",
        meta: { requiresAuth: true, role: "university" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/university/UniversityDashboard.vue"
          ),
      },
      {
        path: "/university/enrollments",
        name: "UniversityEnrollments",
        meta: { requiresAuth: true, role: "university" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/university/UniversityEnrollments.vue"
          ),
      },
      {
        path: "/university/assessments",
        name: "UniversityAssessment",
        meta: { requiresAuth: true, role: "university" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/university/UniversityAssessment.vue"
          ),
      },
      {
        path: "/university/reports",
        name: "UniversityReport",
        meta: { requiresAuth: true, role: "university" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/university/UniversityReport.vue"
          ),
      },
      //University Endpoints End

      // Subadmin Endpoints Start
      {
        path: "/subadmin/dashboard",
        name: "SubadminDashboard",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminDashboard.vue"
          ),
      },
      {
        path: "/subadmin/dashboard-genderwise",
        name: "SubadminDashboardGenderwise",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminDashboardGenderWise.vue"
          ),
      },
      {
        path: "/subadmin/enrollments",
        name: "SubadminEnrollments",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminEnrollments.vue"
          ),
      },
      {
        path: "/subadmin/genderwise-enrollments",
        name: "SubadminEnrollmentsGenderwise",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminEnrollmentsGenderWise.vue"
          ),
      },
      {
        path: "/subadmin/assessments",
        name: "SubadminAssessment",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminAssessment.vue"
          ),
      },
      {
        path: "/subadmin/district",
        name: "SubadminDistrict",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminDistrict.vue"
          ),
      },
      {
        path: "/subadmin/college",
        name: "SubadminCollege",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminCollege.vue"
          ),
      },
      {
        path: "/subadmin/college/current_status",
        name: "SubadminCollegeStatus",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminCollegeStatus.vue"
          ),
      },
      {
        path: "/subadmin/reports",
        name: "SubadminReport",
        meta: { requiresAuth: true, role: "subadmin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/subadmin/SubadminReport.vue"
          ),
      },
      // Subadmin Endpoints End

      // Industries Endpoints Start
      {
        path: "/industries/jobs/",
        name: "IndustriesJobs",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobs/IndustryJobsPage.vue"
          ),
      },
      {
        path: "/industries/job/add/",
        name: "IndustryJobAddPage",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobs/IndustryJobAddPage.vue"
          ),
      },
      {
        path: "/industries/internships/",
        name: "IndustriesInternships",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/internships/IndustryInternshipsPage.vue"
          ),
      },
      {
        path: "/industries/internship/add/",
        name: "IndustryInternshipAddPage",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/internships/IndustryInternshipsAddPage.vue"
          ),
      },
      {
        path: "/industries/internship/edit/:id",
        name: "IndustryInternshipEditPage",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/internships/IndustryInternshipsEditPage.vue"
          ),
      },
      {
        path: "/industries/students-enrollment-list/",
        name: "IndustriesInternshipsStudentEnrollment",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/internships/StudentEnrollment.vue"
          ),
      },
      {
        path: "/industries/mentorship/",
        name: "IndustriesMentorship",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/mentorship/IndustryMentorshipPage.vue"
          ),
      },
      {
        path: "/industries/mentorship/add/",
        name: "IndustryMentorshipAddPage",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/mentorship/IndustryMentorshipAddPage.vue"
          ),
      },
      {
        path: "/industries/trainings/",
        name: "IndustriesTrainings",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/trainings/IndustryTrainingsPage.vue"
          ),
      },
      {
        path: "/industries/training/add/",
        name: "IndustryTrainingsAddPage",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/trainings/IndustryTrainingsAddPage.vue"
          ),
      },
      {
        path: "/industries/job-fair/job-fair-listing/",
        name: "IndustryJobFairListing",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobFair/IndustryJobFairListing.vue"
          ),
      },
      {
        path: "/industries/job-fair/students-enrollment-list/",
        name: "IndustryJobFairStudentsEnrollmentList",
        meta: { requiresAuth: true, role: "industries" },
        component: () =>
          import(
            /* webpackChunkName: "industries" */ "../views/industries/jobFair/IndustryJobFairStudentsEnrollmentList.vue"
          ),
      },
      // Industries Endpoints End

      // Placement Officer Endpoints Start

      {
        path: "/po/students/list",
        name: "PoStudentsList",
        meta: { requiresAuth: true, role: "po" },
        component: () =>
          import(
            /* webpackChunkName: "po" */ "../views/po/students/StudentsListPage.vue"
          ),
      },
      {
        path: "/po/students/placements/list",
        name: "PoStudentsListForPlacementsPage",
        meta: { requiresAuth: true, role: "po" },
        component: () =>
          import(
            /* webpackChunkName: "po" */ "../views/po/students/StudentsListForPlacementsPage.vue"
          ),
      },
      {
        path: "/po/students/placements/update",
        name: "StudentsListCgpaUpdateForPlacementsPage",
        meta: { requiresAuth: true, role: "po" },
        component: () =>
          import(
            /* webpackChunkName: "po" */ "../views/po/students/StudentsListCgpaUpdateForPlacementsPage.vue"
          ),
      },
      {
        path: "/po/mandatory-courses",
        name: "PoAllocateMandatoryCoursesPage",
        meta: { requiresAuth: true, role: "po" },
        component: () =>
          import(
            /* webpackChunkName: "po" */ "../views/college/mandatory-courses/AllocateMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/po/student/placement-status/:student",
        name: "PoStudentPlacementStatus",
        meta: { requiresAuth: true, role: "po" },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/po/placements/StudentPlacementStatus.vue"
          ),
      },
      {
        path: "/po/student/job-fair-enrollment/",
        name: "PoStudentJobFairEnrollment",
        meta: { requiresAuth: true, role: "po" },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/po/placements/JobFairStudentsEnrollmentList.vue"
          ),
      },

      // Placement Officer Endpoints End
      //  College Endpoints Start
      {
        path: "/college/bank-details",
        name: "CollegeBankDetailsListPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/bank-details/CollegeBankDetailsListPage.vue"
          ),
      },
      {
        path: "/college/bank-details/add",
        name: "CollegeAddBankDetailsPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/bank-details/CollegeBankDetailsPage.vue"
          ),
      },
      {
        path: "/college/bank-details/edit/:bankId",
        props: true,
        name: "CollegeBankDetailsEditPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/bank-details/CollegeBankDetailsPage.vue"
          ),
      },
      {
        path: "/college/profile",
        name: "CollegeProfilePage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/profile/CollegeProfilePage.vue"
          ),
      },
      {
        path: "/college/students/add",
        name: "CollegeStudentsAdd",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/invite/AddStudentFormPage.vue"
          ),
      },
      {
        path: "/college/students/list",
        name: "CollegeStudentsList",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/StudentsListPage.vue"
          ),
      },
      {
        path: "/college/hackathon/registrations/",
        name: "StudentsAllocatePage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/AllocateStudentPage.vue"
          ),
      },
      {
        path: "/college/students/placements/list",
        name: "StudentsListForPlacementsPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/StudentsListForPlacementsPage.vue"
          ),
      },
      {
        path: "/college/student/placement-status/:student",
        name: "CollegeStudentPlacementStatus",
        meta: { requiresAuth: true, role: "college" },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/StudentPlacementStatus.vue"
          ),
      },
      {
        path: "/college/students/courseSelections",
        name: "StudentCourseSelectionsAdmin",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/StudentCourseSelectionsDetails.vue"
          ),
      },
      {
        path: "/college/students/placementDetails",
        name: "PlacementDetailsSPOCAdmin",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/StudentsPlacementDetails.vue"
          ),
      },
      {
        path: "/college/students/bulk/upload",
        name: "CollegeArtsStudentsUploadPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/CollegeArtsStudentsUploadPage.vue"
          ),
      },
      {
        path: "/college/students/bulk/details",
        name: "CollegeArtsStudentUploadDetailPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/CollegeArtsStudentUploadDetailPage.vue"
          ),
      },
      {
        path: "/college/students/bulk/history",
        name: "CollegeArtsStudentUploadHistory",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/CollegeArtsStudentUploadHistory.vue"
          ),
      },
      {
        path: "/college/student/:studentId",
        name: "CollegeStudentDetailsPage",
        props: true,
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminStudentDetailsPage.vue"
          ),
      },
      {
        path: "/college/students/validate",
        name: "StudentValidateCasteCertificate",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/validate/StudentValidateCasteCertificate.vue"
          ),
      },
      {
        path: "/college/students/upload",
        name: "InviteStudentsUploadPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/invite/InviteStudentsUploadPage.vue"
          ),
      },
      {
        path: "/college/enrolled/courses",
        name: "StudentEnrolledCourseListPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/enrolled/StudentEnrolledCourseListPage.vue"
          ),
      },
      {
        path: "/college/mandatory-courses",
        name: "AllocateMandatoryCoursesPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AllocateMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/college/faculty-nomination",
        name: "AllocateFacultyToCourse",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AllocateFacultyToCourse.vue"
          ),
      },

      {
        path: "/college/faculty-mapping",
        name: "AllocateCourseFacultyToBranch",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AllocateCourseFacultyToBranch.vue"
          ),
      },
      {
        path: "/college/mandatory-courses/assessment",
        name: "AssessmentMandatoryCoursesPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AssessmentMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/college/mandatory-courses/assessment1",
        name: "AssessmentMandatoryCoursesPage1",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AssessmentMandatoryCoursesPage1.vue"
          ),
      },
      {
        path: "/college/mandatory-courses/syllabus",
        name: "MandatoryCourseSyllabusPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/MandatoryCourseSyllabusPage.vue"
          ),
      },
      {
        path: "/college/mandatory-courses/student-course-status",
        name: "MandatoryCourseStatusPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/MandatoryCourseStatusPage.vue"
          ),
      },
      {
        path: "/college/microsoft-update",
        name: "MicrosoftAssessmentUpdate",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/temp/MicrosoftAssesmentUpdate.vue"
          ),
      },
      {
        path: "/college/announcements",
        name: "CollegeAnnouncements",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/announcements/CollegeAnnouncements.vue"
          ),
      },
      {
        path: "/college/downloadSpocCertificate",
        name: "CollegeSpocCertificate",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/downloads/DownloadSpocCertificate.vue"
          ),
      },
      {
        path: "/college/principal/",
        name: "CollegePrincipalListPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/data/CollegePrincipalListPage.vue"
          ),
      },
      {
        path: "/college/placement-officer/",
        name: "CollegePlacementsOfficerListPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/data/CollegePlacementsOfficerListPage.vue"
          ),
      },
      {
        path: "/college/placement-officer/add",
        name: "CollegeAddPlacementOfficerPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/data/CollegeAddPlacementOfficerPage.vue"
          ),
      },
      {
        path: "/college/placement-officer/edit/:poId",
        props: true,
        name: "CollegePlacementOfficerEditPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/data/CollegeAddPlacementOfficerPage.vue"
          ),
      },
      {
        path: "/college/faculty/",
        name: "CollegeFacultyListPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/faculty/CollegeFacultyListPage.vue"
          ),
      },
      {
        path: "/college/faculty/add",
        name: "CollegeAddFacultyPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/faculty/CollegeAddFacultyPage.vue"
          ),
      },
      {
        path: "/college/faculty/edit/:facultyId",
        props: true,
        name: "CollegeFacultyEditPage",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/faculty/CollegeAddFacultyPage.vue"
          ),
      },
      //    College Endpoints End
      //    Student Endpoints Start
      {
        path: "/students/dashboard",
        name: "StudentsDashboardPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/v2/StudentsDashboardPage.vue"
          ),
      },
      {
        path: "/students/home",
        name: "StudentsHomePage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/StudentsHomePage.vue"
          ),
      },
      {
        path: "/students/psychometric",
        name: "StudentPsychometricHome",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/psychometric/StudentPsychometricHome.vue"
          ),
      },
      {
        path: "/students/psychometric/test/:testType",
        name: "StudentPsychometric",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/psychometric/PsychometricPage.vue"
          ),
      },
      {
        path: "/jobs",
        name: "StudentJobsHomePage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/jobs/StudentJobsHomePage.vue"
          ),
      },
      {
        path: "/jobs/:sectorId",
        name: "StudentJobSectorCompaniesPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/jobs/StudentJobSectorCompaniesPage.vue"
          ),
      },
      {
        path: "/jobs/:sectorId/:companyId",
        name: "StudentCompanyJobsPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/jobs/StudentCompanyJobsPage.vue"
          ),
      },
      {
        path: "/course/partner/:courseId",
        name: "CoursePartnerPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/CoursePartnerPage.vue"
          ),
      },
      {
        path: "/courses",
        name: "StudentCourseListPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/StudentCourseListPage.vue"
          ),
      },
      {
        path: "/courses-bak",
        name: "StudentCourseListPageBak",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/StudentCourseListPageBak.vue"
          ),
      },
      {
        path: "/mandatory/courses",
        name: "MandatoryCoursesPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/MandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/mandatory/courses/certificates",
        name: "CertificateDownload",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/CertificateDownload.vue"
          ),
      },
      {
        path: "/placement/profile",
        name: "JobportalProfileUpdate",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/dialog/JobportalProfileUpdate.vue"
          ),
      },
      {
        path: "/courses/my",
        name: "StudentMyCourseListPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/StudentMyCourseListPage.vue"
          ),
      },
      {
        path: "/course/:courseId",
        name: "StudentCourseDetailPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/StudentCourseDetailPage.vue"
          ),
      },
      {
        path: "/course/certificate/:certificateId",
        name: "StudentCourseCertificate",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/courses/StudentCourseCertificate.vue"
          ),
      },
      {
        path: "/student/profile",
        name: "StudentProfilePage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/profile/StudentProfilePage.vue"
          ),
      },
      {
        path: "/student/linkedin_profile_update",
        name: "StudentProfilePage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/profile/LinkedinProfile.vue"
          ),
      },
      {
        path: "/student/internship_courses",
        name: "StudentInternshipPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/profile/Internship.vue"
          ),
      },
      {
        path: "/student/internships-list",
        name: "StudentInternshipsListPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/profile/InternshipsList.vue"
          ),
      },
      {
        path: "/student/pending",
        name: "StudentApprovalPending",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/StudentApprovalPending.vue"
          ),
      },
      {
        path: "/student/placement-status",
        name: "StudentPlacementStatus",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/StudentPlacementStatus.vue"
          ),
      },
      {
        path: "/student/sigaram_thodu",
        name: "StudentSigaramThoduRegistration",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/SigaramThodu.vue"
          ),
      },
      // Student FS Courses
      {
        path: "/fs/partners/",
        name: "FSPartnersPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/fs/FSPartnersList.vue"
          ),
      },
      {
        path: "/fs/partner/:partnerId",
        name: "FSPartnerCoursesPage",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/fs/FSPartnerCourses.vue"
          ),
      },
      {
        path: "/courses/fs/my",
        name: "FSCourseEnrollments",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/fs/FSCourseEnrollments.vue"
          ),
      },
      {
        path: "/students/saml-dashboard",
        name: "SamlStudentsDashboardPage",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/v2/SamlStudentsDashboardPage.vue"
          ),
      },
      //Student FS Courses End
      //    Student Endpoints End
      //    Course Endpoints Start

      //    Courses Endpoints End
      //    KP Endpoints Start
      {
        path: "/kp/enrolled",
        name: "KPStudentEnrolledCourseListPage",
        meta: { requiresAuth: true, role: "kp" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/kp/enrolled/KPStudentEnrolledCourseListPage.vue"
          ),
      },
      {
        path: "/kp/fs/enrolled",
        name: "KPFSCourseEnrollments",
        meta: { requiresAuth: true, role: "kp" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/kp/enrolled/KPFSCourseEnrollments.vue"
          ),
      },
      //    KP Endpoints End
      {
        path: "/faculty/students/list",
        name: "FacultyStudentsList",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/students/StudentsListPage.vue"
          ),
      },
      {
        path: "/faculty/mandatory-courses/assessment",
        name: "FacultyAssessmentMandatoryCoursesPage",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AssessmentMandatoryCoursesPage.vue"
          ),
      },

      {
        path: "/faculty/mandatory-courses/assessment1",
        name: "FacultyAssessmentMandatoryCoursesPage1",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/AssessmentMandatoryCoursesPage1.vue"
          ),
      },

      {
        path: "/faculty/mandatory-courses/attendance",
        name: "FacultyAttendancePage",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/FacultyAttendancePage.vue"
          ),
      },
      {
        path: "/faculty/mandatory-courses/attendance2",
        name: "FacultyAttendanceNewPage",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/mandatory-courses/FacultyAttendanceNewPage.vue"
          ),
      },
      {
        path: "/student/ambassador/nomination",
        name: "StudentAmbassadorNomination",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/student/student_nomination/index.vue"
          ),
      },
      {
        path: "/faculty/fdp/courses",
        name: "FacultyCourses",
        meta: { requiresAuth: true, role: "faculty" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/faculty/FacultyCourses.vue"
          ),
      },
      // {
      //   path: '/college/mandatory-courses/syllabus',
      //   name: 'MandatoryCourseSyllabusPage',
      //   meta: { requiresAuth: true, role: "faculty", },
      //   component: () => import(/* webpackChunkName: "college" */ '../views/college/mandatory-courses/MandatoryCourseSyllabusPage.vue')
      // },
      // faculty Endpoints End
    ],
  },
  {
    path: "/",
    name: "NoAuth",
    component: () =>
      import(/* webpackChunkName: "base" */ "../layouts/NoNavBarLayout.vue"),
    children: [
      {
        path: "/recruitment/recruitment_details_form",
        name: "RecruitmentDetailsForm",
        props: true,
        meta: { isLoginPage: false, requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/RecruitmentDetailsForm.vue"
          ),
      },
      // {
      //   path: "",
      //   redirect: "/login",
      // },
      //  College Endpoints Start
      {
        path: "/college-registration/:invitationId",
        name: "CollegeRegistration",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/CollegeRegistration.vue"
          ),
      },
      {
        path: "/college/payment",
        name: "CollegePayment",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/payment/CollegePaymentPage.vue"
          ),
      },
      {
        path: "/college/payment-success",
        name: "CollegePaymentSuccess",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/payment/PaymentSuccessPage.vue"
          ),
      },
      {
        path: "/college/payment-failed",
        name: "CollegePaymentFailed",
        meta: { requiresAuth: true, role: "college" },
        component: () =>
          import(
            /* webpackChunkName: "college" */ "../views/college/payment/PaymentFailedPage.vue"
          ),
      },
      //  College Endpoints End
      //  Student Endpoints Start
      {
        path: "/student-registration/:invitationId",
        name: "StudentRegistration",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/StudentRegistration.vue"
          ),
      },
      {
        path: "/student/payment",
        name: "StudentPayment",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/payment/StudentPaymentPage.vue"
          ),
      },
      {
        path: "/student/payment-success",
        name: "StudentPaymentSuccess",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/payment/StudentPaymentSuccessPage.vue"
          ),
      },
      {
        path: "/student/payment-failed",
        name: "StudentPaymentFailed",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/payment/StudentPaymentFailedPage.vue"
          ),
      },
      {
        path: "/student/fill/placement-status",
        name: "StudentFullPagePlacementStatus",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/StudentFullPagePlacementStatus.vue"
          ),
      },
      {
        path: "/student/fill/course-selection",
        name: "StudentCourseSelection",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/StudentCourseSelection.vue"
          ),
      },
      {
        path: "/student/fill/update-courses",
        name: "StudentCourseUpdate",
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/placements/StudentCourseUpdate.vue"
          ),
      },

      //  Student Endpoints End
      //  PassOut Student Endpoints End

      {
        path: "/passout-student-registration/",
        name: "PassOutStudentRegistration",
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/passout/PassOutStudentRegistration.vue"
          ),
      },
      //  PassOut Student Endpoints End
      {
        path: "/login",
        name: "Login",
        meta: { isLoginPage: true },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth/UsernameLoginPage.vue"
          ),
      },
      {
        path: "/student-verification/:studentId",
        name: "StudentVerification",
        props: true,
        meta: { requiresAuth: true, role: "student" },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth/StudentVerification.vue"
          ),
      },
      /* {
        path: "/mobile/login",
        name: "MobileLogin",
        meta: { isLoginPage: true },
        component: () =>
          import(/* webpackChunkName: "login" */ /* "../views/auth/LoginPage.vue"),
      }, */
      {
        path: "/forgot-username",
        name: "ForgotUsername",
        meta: { isLoginPage: true },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth/ForgotUsernamePage.vue"
          ),
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        meta: { isLoginPage: true },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth/ForgotPasswordPage.vue"
          ),
      },
      {
        path: "/validate/certificate/:certificateId",
        name: "ValidateCertificate",
        props: true,
        meta: { isLoginPage: false, requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/certificate/ValidateCertificatePage.vue"
          ),
      },

      // {
      //   path: "/eoi",
      //   name: "EOILoginPage",
      //   meta: { isLoginPage: true, role: "eoi" },
      //   component: () =>
      //     import(/* webpackChunkName: "eoi" */ "../views/eoi/EOILoginPage.vue"),
      // },
      {
        path: "/sigaramthodu_eoi/",
        name: "SigaramThoduEOILoginPage",
        meta: { isLoginPage: true, role: "eoi" },
        component: () =>
          import(
            /* webpackChunkName: "eoi" */ "../views/eoi/SigaramThoduEOILoginPage.vue"
          ),
      },
      {
        path: "/nmfs_eoi",
        name: "TNSkillsEOILogin",
        meta: { isLoginPage: true, role: "eoi" },
        component: () =>
          import(
            /* webpackChunkName: "eoi" */ "../views/eoi/TNSkillsEOILogin.vue"
          ),
      },
      {
        path: "/eoi_nmfs",
        name: "NMFS_EOI",
        meta: { isLoginPage: true, role: "eoi" },
        component: () =>
          import(/* webpackChunkName: "eoi" */ "../views/eoi/nmfs/index.vue"),
      },

      {
        path: "/upsc_hall_ticket_upload",
        name: "UPSCRegistration",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/upsc/UPSCRegistration.vue"),
      },

      {
        path: "/upsc/candidate_details",
        name: "UPSCCandidate_details",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/upsc/UPSCCandidateDetails.vue"),
      },
      {
        path: "/competitive_exams",
        name: "Competitive_Exams",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/coaching/Competitive_Exams.vue"),
      },
      {
        path: "/ce/all/reports/dashboard",
        name: "Competitive_Exams_Reports",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/coaching/index.vue"),
      },
      {
        path: "/ce/banking/ssc/railways",
        name: "Banking_SSC_Railways",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import("../views/coaching/Competitive_Exams_Reports.vue"),
      },
      {
        path: "/ce/upsc/prelims",
        name: "upsc_prelims",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import("../views/coaching/upsc_Prelims/Prelims_Reports.vue"),
      },
      {
        path: "/ce/upsc/prelims/hallticket",
        name: "upsc_prelims_hallticket",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import(
            "../views/coaching/upsc_Prelims/Prelims_hallticket_report.vue"
          ),
      },
      {
        path: "/prelims_registration",
        name: "UpscPrelimsForm",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import("../views/coaching/upsc_Prelims/UpscPrelimsForm.vue"),
      },
      {
        path: "/prelims_application",
        name: "UpscPrelimsApplication",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import("../views/coaching/upsc_Prelims/Prelims_Application.vue"),
      },
      {
        path: "/prelims_hallticket",
        name: "UpscPrelimsHallticket",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import("../views/coaching/upsc_Prelims/Prelims_Hallticket.vue"),
      },
      // {
      //   path: '/upsc_registration',
      //   name: 'Upsc_Registration',
      //   meta: { isLoginPage: true, role: 'tp' },
      //   component: () => import( '../views/coaching/upsc/Upsc_Scholar_Registration.vue')
      // },
      {
        path: "/niral_thiruvizha",
        name: "NiralThiruvizha",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/coaching/niral_thiruvizha/index.vue"),
      },
      {
        path: "/tnskills",
        name: "TNskills",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/coaching/tnSkills/index.vue"),
      },
      {
        path: "/download_upsc_registration/",
        name: "download_upsc_registration",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/coaching/upsc/Print_Application.vue"),
      },
      {
        path: "/nmschools/schoologin/",
        name: "NMSchools",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/nmschools/schoologin.vue"),
      },
      {
        path: "/nmschools/schoolcourse/",
        name: "NMSchoolsCourse",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/nmschools/schoolcourse.vue"),
      },

      {
        path: "/upsc_all_registrations",
        name: "UPSCAdminRegistrations",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/upsc/UPSCAdminRegistrations.vue"),
      },

      {
        path: "/nm_event_registrations",
        name: "NMEventRegistrations",
        meta: { isLoginPage: true, role: "tp" },
        component: () => import("../views/upsc/NMEventRegistrations.vue"),
      },

      // TP Login

      {
        path: "/tp",
        name: "TPLoginPage",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import(/* webpackChunkName: "eoi" */ "../views/kp/TPLoginPage.vue"),
      },
      {
        path: "/technosmile_registration_form/",
        name: "TechnosmileRegistationForm",
        meta: { isLoginPage: true, role: "tp" },
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/student/profile/TechnosmileRegForm.vue"
          ),
      },
      {
        path: "/tpDashboard",
        name: "TPDashboardPage",
        meta: { isLoginPage: false, role: "tp" },
        component: () =>
          import(
            /* webpackChunkName: "tp" */ "../views/kp/TPDashboardPage.vue"
          ),
      },
      {
        path: "/admin/financialyear/",
        name: "AdminFinacialYear",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminFinancialYearPage.vue"
          ),
      },
      {
        path: "/admin/mandatorycourse/",
        name: "AdminMandatoryCourses",
        meta: { requiresAuth: true, role: "admin" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/AdminMandatoryCoursesPage.vue"
          ),
      },
      {
        path: "/workshop/niralthiruvizha",
        name: "NiralThiruvizhaWorkShop",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/workshop/NiralThiruvizhaWorkShop.vue"
          ),
      },
      // {
      //   path: '/tpStudentAttendance',
      //   name: 'TPDashboardPage',
      //   meta: { isLoginPage: false, role: 'tp' },
      //   component: () => import(/* webpackChunkName: "tp" */ '../views/kp/TPStudentAttendancePage.vue')
      // },

      // {
      //   path: '/admin/nmstats-internal',
      //   name: 'AdminDashboard',
      //   component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminDashboard.vue')
      // },

      {
        path: "/seminar-registration-form",
        name: "SeminarRegistrationForm",
        props: true,
        meta: { isLoginPage: false, requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/SeminarRegistrationForm.vue"
          ),
      },
      {
        path: "/saml-login",
        name: "SamlLogin",
        meta: { isLoginPage: true },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/auth/SamlLoginPage.vue"
          ),
      },
      {
        path: "/seminar/overseas_reports/",
        name: "OverSeasOpenReports",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/Seminar/OverSeasReports.vue"
          ),
      },


      {
        path: "/valeo-internship-form",
        name: "ValoeInternshipForm",
        props: true,
        meta: { isLoginPage: false, requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/ValeoInternshipForm.vue"
          ),
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  console.log({ to });
  let query = to.query;
  if (query.lang) {
    localStorage.setItem("lang", query.lang.toString() || "en");
  }

  let tempisStudentVerified = sessionStorage.getItem("isStudentVerified");
  let isStudentVerified = tempisStudentVerified
    ? JSON.parse(tempisStudentVerified)
    : null;

  let tempStudentId = sessionStorage.getItem("studentId");
  let studentId = tempStudentId ? JSON.parse(tempStudentId) : null;

  if (isStudentVerified === false && store.state.isLoggedIn) {
    if (to.name === "StudentVerification") {
      next();
    } else if (from.name === "StudentVerification" && to.name === "Login") {
      next({
        name: "StudentVerification",
        params: { studentId: studentId },
      });
    } else if (from.name == undefined && to.name === "Login") {
      next({
        name: "StudentVerification",
        params: { studentId: studentId },
      });
    } else if (from.name === "StudentVerification") {
      next({
        name: "StudentVerification",
        params: { studentId: studentId },
      });
      return;
    }

    next({ name: "Login" });
  } else {
    // TEMP LOGIC FOR SAML INTEGRATION: REMOVE LATER
    if (from.name === "SamlLogin") {
      next();
    } else {
      if (to.meta.requiresAuth && !store.state.isLoggedIn)
        next({ name: "Login" });
      else if (to.meta.isLoginPage && store.state.isLoggedIn) {
        if (to.meta.role == "eoi") {
          next();
        } else {
          next({ name: "Home" });
        }
      } else if (to.meta.role == "tp") {
        next();
      } else {
        let metaRole = to.meta.role;
        if (metaRole == "eoi") {
          next();
        } else {
          if (!metaRole) {
            next();
          } else {
            // @ts-ignore
            const user: any = store.state.activeUser;
            const role = user["account_role"];
            let roleCheck = "";
            if (role === 1 || role === 2 || role === 3 || role === 5) {
              roleCheck = "admin";
            }
            if (role === 4) {
              roleCheck = "district";
            }
            if (role === 6 || role === 7) {
              roleCheck = "college";
            }
            if (role === 8) {
              roleCheck = "student";
            }
            if (role === 11) {
              roleCheck = "kp";
            }
            if (role === 14) {
              roleCheck = "industries";
            }
            if (role === 16) {
              roleCheck = "faculty";
            }
            if (role === 17) {
              roleCheck = "university";
            }
            if (role === 18) {
              roleCheck = "po";
            }
            if (role === 19) {
              roleCheck = "pm";
            }
            if (role === 20) {
              roleCheck = "pd";
            }
            if (role === 21 || role === 22 || role === 23) {
              roleCheck = "subadmin";
            }
            // @ts-ignore
            if (roleCheck.indexOf(metaRole) === -1) {
              next({ name: "Home" });
            } else {
              // @ts-ignore
              if (
                to.path != "/student/pending" &&
                role === 8 &&
                user["is_graduate"] &&
                user["student_details"].status == 5
              ) {
                next({ name: "StudentApprovalPending" });
              } else {
                next();
              }
            }
          }
        }
      }
    }
  }
});
export default router;
