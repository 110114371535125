import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import adminIndustriesStore from "@/store/admin/adminIndustriesStore";

const actions: ActionTree<any, any> = {
  getAdminEOIsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      // commit("resetData");
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/eoi/admin/list/`, { params: data });
        commit("setEOIListData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  downloadAdminEOIsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/eoi/admin/list/`, { params: data });
        dispatch('downloadStringToCSV', {data: response.data, filename: 'eois_list.csv'});
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  
  getAdminEOIDetails({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/eoi/admin/details/`, { params: data });
        commit("setEOIData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  updateMicrosoftEmail({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
        const formData = new FormData();

        Object.keys(data).forEach((key: string) => {
            formData.append(key, data[key]);
        });
        commit("SET_LOADING", true);
        try {

            const response = await axios.post("/api/v1/student/profile/ms-email-update/", formData);

            commit("SET_LOADING", false);
            resolve(response.data);
        } catch (err: any) {
            commit("SET_LOADING", false);
            try {
                reject(err.response.data.message);
            } catch (e) {
                reject(err.message);
            }
        }
    });
},


  // updateMicrosoftEmail({commit, dispatch}, data: any) {
  //   // eslint-disable-next-line no-async-promise-executor

  //   return new Promise(async (resolve, reject) => {
  //       const formData = new FormData();

  //       Object.keys(data).forEach((key: string) => {
  //           formData.append(key, data[key]);
  //       });
  //       commit("SET_LOADING", true);
  //       try {

  //           const response = await axios.post("/api/v1/student/profile/ms-email-update", formData);

  //           commit("SET_LOADING", false);
  //           resolve(response.data);
  //       } catch (err: any) {
  //           commit("SET_LOADING", false);
  //           try {
  //               reject(err.response.data.message);
  //           } catch (e) {
  //               reject(err.message);
  //           }
  //       }
  //   });
  // }

};
const adminEOIStore: Module<any, any> = {
  state: () => ({
    eois: [],
    eoi: null,
    totalPages: 0,
    limit: 20,
    page: 0,
  }),
  mutations: {
    resetData(state) {
      state.eois = [];
      state.totalPages = 0;
      state.limit = 20;
      state.page = 0;
    },
    setEOIListData(state, data) {
        state.eois = data.eois;
        state.totalPages = Math.ceil(data.filters.total_count / data.filters.limit);
        state.limit = data.filters.limit;
        state.page = data.filters.page;
    },
    setEOIData(state, data) {
        state.eoi = data;
    },
  },
  actions,
  getters: {
    adminEOIs(state) {
        return state.eois;
    },
    eoisTotalPages(state) {
      return state.totalPages;
    },
    eoisPage(state) {
      return state.page;
    },
    adminEOI(state) {
        return state.eoi;
    }
  },
};

export default adminEOIStore;
