import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

// const actions: ActionTree<any, any> = {
//     getFDPFeedbacks({ commit, dispatch }, data: any) {
//         // eslint-disable-next-line no-async-promise-executor
//         return new Promise(async (resolve, reject) => {
    
//           // commit("resetData");
//           commit("SET_LOADING", true);
//           try {
//             const response = await axios.get(`/api/v1/college/courses/faculty_feedbacks/`, { params: data });
//             commit("setEOIListData", response.data);
//             resolve(response.data);
//           } catch (err: any) {
//             try {
//               reject(err.response.data.message);
//             } catch (e) {
//               reject(err.message);
//             }
//           }
//           commit("SET_LOADING", false);
//         });
//       }

// };
const adminFeedbackStore: Module<any, any> = {
  
};

export default adminFeedbackStore;