import axios from "@/http/auth";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  v2RequestMobileOTP({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v2/login/otp/request/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  v2VerifyMobileOTP({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v2/login/otp/verify/",
          formData
        );
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;
        commit("saveToken", json.access);
        commit("saveRefreshToken", json.refresh);
        // commit("saveUser", JSON.stringify(json));
        dispatch("fetchProfileDetails");
        resolve(response.data);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  performLogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/login/", formData);
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;

        if (json.access) {
          commit("saveToken", json.access ? json.access : null);
          commit("saveRefreshToken", json.refresh ? json.refresh : null);
          // commit("saveUser", JSON.stringify(json));

          // Profile Details dispatch has been moved to the UsernameLoginPage.vue
          // dispatch("fetchProfileDetails");
        }
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authSendUsernameToEmail({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/forgot/username/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authForgotPasswordStep1({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/forgot/password/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authForgotPasswordStep2({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/forgot/password/reset/",
          formData
        );
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  tokenRefresh({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      const refreshToken = localStorage.getItem("refresh");
      if (refreshToken) {
        formData.append("refresh", refreshToken);

        try {
          const response = await axios.post("/api/v1/token/refresh/", formData);
          commit("SET_LOGGED_IN", true);
          const json = response.data;
          commit("saveToken", json.access);
          resolve(response.data);
        } catch (err: any) {
          dispatch("logout");
          try {
            reject(err.response.data.message);
          } catch (e) {
            reject(err.message);
          }
        }
      } else {
        dispatch("logout");
      }
    });
  },

  fetchStudentEmailAndPhoneNumber({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/fetch_student_email_phone_number`,
          formData
        );

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      // commit("SET_LOADING", false);
    });
  },

  sendEmailOtpForStudent({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/send_email_otp_for_student_login`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  sendPhoneOtpForStudent({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/student/send_sms_otp_for_student_login`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  verifyEmailOtp({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `api/v1/student/verify_email_otp_for_student_login`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      // commit("SET_LOADING", false);
    });
  },

  verifyPhoneOtp({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      // commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `api/v1/student/verify_sms_otp_for_student_login`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      // commit("SET_LOADING", false);
    });
  },

  updateStudentEmailOrPhone({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(
          `api/v1/student/update_student_email_phone_number`,
          formData
        );
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  SamlperformLogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/saml_login/", formData);
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;
        commit("saveToken", json.access ? json.access : null);
        commit("saveRefreshToken", json.refresh ? json.refresh : null);
        // commit("saveUser", JSON.stringify(json));
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
};
const authStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveToken(state, token) {
      localStorage.setItem("token", token);
      this.commit("SET_LOGGED_IN", true);
    },
    saveRefreshToken(state, token) {
      localStorage.setItem("refresh", token);
    },
  },
  actions,
  getters: {},
};

export default authStore;
