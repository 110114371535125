import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getInternshipTitlesByIndustry({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_internship_by_industry`, { params: data });
        commit("SET_INTERNSHIP_TITLES_LIST", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getAllIndustries({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_industry_filter`, { params: data });
        commit("SET_INDUSTRIES_LIST", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  getCollegesByInternship({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_college_list_by_internship`, { params: data });
        commit("SET_COLLEGES_LIST", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

	getCollegeWiseInternshipReport({ commit }, data: any) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_collegewise_internship`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
	},

	getStudentWiseInternshipReport({ commit }, data: any) {
		// eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_studentwise_internship/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
	},

  getStudentDetailsByIndustryInternship({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/industry/fetch_internship_details/`, { params: data });
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  approveRejectInternshipStudentEnrollment({ commit }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
          const response = await axios.put("/api/v1/industry/update_internship_details/", formData);
          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
    });
  }
};

const internshipStore: Module<any, any> = {
  state: () => ({
    internshipTitlesList: [],
    industriesList: [],
    collegesList: [],
  }),
  mutations: {
    SET_INTERNSHIP_TITLES_LIST(state, data) {
      state.internshipTitlesList = data;
    },
    SET_INDUSTRIES_LIST(state, data) {
      state.industriesList = data;
    },
    SET_COLLEGES_LIST(state, data) {
      state.collegesList = data;
    },
  },
  actions,
  getters: {
    internshipTitlesList(state) {
      return state.internshipTitlesList || [];
    },
    industriesList(state) {
      return state.industriesList || [];
    },
    collegesList(state) {
      return state.collegesList || [];
    },
  },
};

export default internshipStore;
