import { Module, ActionTree } from "vuex";
import eoiAuth from "@/http/eoiAuth";

const actions: ActionTree<any, any> = {
  performEOILogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/eoi/otp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  nmfsSENDOTP({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/nmfs_eoi/sendOTP/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  verifyNMFSOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/nmfs_eoi/verifyOTP/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("eoi-mobile", response.data.mobile_number);
        localStorage.setItem("eoi-token", response.data.access);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  performEOIVerifyOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/eoi/otp/verification/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("eoi-mobile", response.data.mobile_number);
        localStorage.setItem("eoi-token", response.data.access);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  performEOISubmission({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.get("/api/v1/eoi/detailsV2/", { params: {limit: 1000}});
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  performEOIFormSubmit({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });
      
      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/eoi/detailsV2/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  getUpscStudentData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/candidate-details/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("upsc-mobile", response.data.mobile_number);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  updateUpscStudentData({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/update-candidate-details/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  updateUpscStudentHallticket({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/update-candidate-hallticket/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  getUserMobileOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/mobile_otp/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("upsc-mobile", response.data.mobile_number);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  sendMobileOtp({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/bank_ssc_railway_coaching/sendMobileOtp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  validateMobileOtp({ commit, dispatch }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/bank_ssc_railway_coaching/validateMobileOtp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  StudentExamDetails({ commit, dispatch }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/bank_ssc_railway_coaching/Details/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
fetchStudentDetails({ commit, dispatch }, phone_number) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.get(`/api/v1/bank_ssc_railway_coaching/Get_Student_Details/?phone_number=${phone_number}`);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
fetchNmfsDetails({ commit, dispatch }, mobile) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.get(`/api/v1/nmfs_eoi/getNMFS/?mobile=${mobile}`);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
fetchAllStudentDetails({ commit, dispatch }) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.get(`/api/v1/bank_ssc_railway_coaching/Get_All_Student_Details/`);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
fetchAllPrelimsHallticketDetails({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/upsc_mains_shcolarship/hallticket_prelims_details/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
fetchAllPrelimsStudentDetails({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/upsc_mains_shcolarship/all_prelims_details/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

  performTnSkillsEOISubmission_st1({commit, dispatch}, data: any) {
    // eslint-disable-next-line no-async-promise-executor

    return new Promise(async (resolve, reject) => {
        const formData = new FormData();

        Object.keys(data.data).forEach((key: string) => {
            formData.append(key, data.data[key]);
        });
        commit("SET_LOADING", true);
        try {
            if (data.reg_id){
              const response = await eoiAuth.patch(`/api/v1/eoi/eoi_registration/${data.reg_id}/`, formData);
              commit("SET_LOADING", false);
              resolve(response.data);
            }
            else{
              const response = await eoiAuth.post(`/api/v1/eoi/eoi_registration/${data.mobile}/`, formData);
              commit("SET_LOADING", false);
              resolve(response.data);
            }
            
        } catch (err: any) {
            commit("SET_LOADING", false);
            try {
                reject(err.response.data.message);
            } catch (e) {
                reject(err.message);
            }
        }
    });
},

fetchAllNominationStudents({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/student_nomination/get_nominee_students/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

sendOtp_niralThiruvizha({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/niral_thiruvizha/sent_otp/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
getData_niralThiruvizha({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/niral_thiruvizha/getData/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

verifyOtp_niralThiruvizha({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/niral_thiruvizha/verify_otp/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

sendOtp_tnskills({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/tnskills/sent_otp/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
geStudentPlacementRecommendation({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/placements/get_spr/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
getData_tnskills({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/tnskills/getData/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

verifyOtp_tnskills({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/nmfs_eoi/tnskills/verify_otp/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

sendNominationStudent({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/student_nomination/nominee_student/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
sigaramThoduRegistration({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/placements/registration/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
sigaramThoduRegistrationData({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/placements/get/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

removeNominationStudent({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/student_nomination/delete_nominated_student/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},
getStudentAmbassadorReports({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/student_nomination/get_nominated_students_report/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

getNominatedStudentsData({ commit, dispatch }, data: any) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    });

    commit("SET_LOADING", true);
    try {
      const response = await eoiAuth.post("/api/v1/student_nomination/nominated_students/", formData);
      commit("SET_LOADING", false);
      resolve(response.data);
    } catch (err: any) {
      commit("SET_LOADING", false);
      try {
        reject(err.response.data.message);
      } catch (e) {
        console.error(e);
        reject(err.message);
      }
    }
  });
},

performTnskillsEOISubmission_st2({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
          formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.post(`/api/v1/eoi/eoi_previousexp/${data.reg_id}/`, formData);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},

performTnSkillsEOISubmission_st3({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
          formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.post(`/api/v1/eoi/eoi_proposedcourse/${data.reg_id}/`, formData);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},

performTnSkillsEOISubmission_st4({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data.data).forEach((key: string) => {
          formData.append(key, data.data[key]);
      });
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.post(`/api/v1/eoi/eoi_trainer_details/${data.reg_id}/`, formData);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
getEOIDetailsTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.get(`/api/v1/eoi/eoi_registration/${data.loggedin_user_mobile}/`);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},

getPreviousExpDetailsTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.get(`/api/v1/eoi/eoi_previousexp/${data.reg_id}/`);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
getProposedCoursesTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.get(`/api/v1/eoi/eoi_proposedcourse/${data.reg_id}/`);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
getTrainerDetailsTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.get(`/api/v1/eoi/eoi_trainer_details/${data.reg_id}/`);

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},

deletePreviousExpDetailsTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.delete(`/api/v1/eoi/eoi_previousexp/0/`,  { params: data });

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
deleteProposedCoursesTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.delete(`/api/v1/eoi/eoi_proposedcourse/0/`,  { params: data });

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
deleteTrainerDetailsTnSkills({commit, dispatch}, data: any) {
  // eslint-disable-next-line no-async-promise-executor

  return new Promise(async (resolve, reject) => {
      commit("SET_LOADING", true);
      try {

          const response = await eoiAuth.delete(`/api/v1/eoi/eoi_trainer_details/0/`,  { params: data });

          commit("SET_LOADING", false);
          resolve(response.data);
      } catch (err: any) {
          commit("SET_LOADING", false);
          try {
              reject(err.response.data.message);
          } catch (e) {
              reject(err.message);
          }
      }
  });
},
  validateOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/mobile_otp_validate/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("upsc-mobile", response.data.mobile_number);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },

  ADD_NMFS_Org_DETAILS({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/nmfs_eoi/addNMFS_Org/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  ADD_NMFS_Course_DETAILS({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/nmfs_eoi/addNMFS_course/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  getTnskillsEOIRegistrations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
  
    return new Promise(async (resolve, reject) => {
        commit("SET_LOADING", true);
        try {
  
            const response = await eoiAuth.get(`/api/v1/eoi/all_registrations/`);
  
            commit("SET_LOADING", false);
            resolve(response.data);
        } catch (err: any) {
            commit("SET_LOADING", false);
            try {
                reject(err.response.data.message);
            } catch (e) {
                reject(err.message);
            }
        }
    });
  },
  getTnskillsFinancialAll({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
  
    return new Promise(async (resolve, reject) => {
        commit("SET_LOADING", true);
        try {
  
            const response = await eoiAuth.get(`/api/v1/nmfs_eoi/getNmfsAll/`);
  
            commit("SET_LOADING", false);
            resolve(response.data);
        } catch (err: any) {
            commit("SET_LOADING", false);
            try {
                reject(err.response.data.message);
            } catch (e) {
                reject(err.message);
            }
        }
    });
  },
  getAllRegistrations({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/upsc/all_registrations/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  sigaramThoduPerformEOIVerifyOTP({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/users/eoi/sigaram_thodu/otp/verification/", formData);
        commit("SET_LOADING", false);
        localStorage.setItem("eoi-mobile", response.data.mobile_number);
        localStorage.setItem("eoi-token", response.data.access);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
 
  sigaramThoduEOILogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/users/eoi/sigaram_thodu/otp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  sendOtpNiralThiruvizhaWorkshop({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/industry/niral_thiruvizha_send_otp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  verifyOtpNiralThiruvizhaWorkshop({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/industry/niral_thiruvizha_verify_otp/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  sumbitFormNiralThiruvizhaWorkshop({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/industry/niral_thiruvizha_submit_form/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  fetchSumbittedDataNiralThiruvizhaWorkshop({ commit }, data: any) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
  
      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
  
      commit("SET_LOADING", true);
      try {
        const response = await eoiAuth.post("/api/v1/industry/niral_thiruvizha_fetch_sumbitted_data/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  
};
const eoiStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
  },
  actions,
  getters: {},
};

export default eoiStore;
