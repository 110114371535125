import axios from "@/http/http";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  getAPIClientsList({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/lms/clients`, { params: data });

        commit("setAPIClientsData", response.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  checkAPIClientsWorking({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
        const response = await axios.post(`/api/v1/lms/client/api/integration/check/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addNewAPIClient({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key: string) => {
          formData.append(key, data[key]);
        });
        const response = await axios.post(`/api/v1/lms/client/`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateAPIClient({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const formData = new FormData();

        Object.keys(data.data).forEach((key: string) => {
          formData.append(key, data.data[key]);
        });
        const response = await axios.post(`/api/v1/lms/client/${data.client_id}`, formData);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const adminAPIClients: Module<any, any> = {
  state: () => ({
    apiClientsList: [],
  }),
  mutations: {
    resetData(state) {
      state.apiClientsList = [];
    },
    setAPIClientsData(state, data) {
      state.apiClientsList = data.clients_list;
    },
  },
  actions,
  getters: {
    apiClientsList(state) {
        return state.apiClientsList;
    },
  },
};

export default adminAPIClients;
