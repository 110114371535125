import axios from "@/http/http";
import { Module, ActionTree } from "vuex";
import moment from "moment";

const actions: ActionTree<any, any> = {
  getCollegePOs({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/placement_officers/`, { params: data });
        commit('setCollegePlacementOfficer', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  fetchSingleCollegePO({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/college/placement_officer/`, { params: data });
        commit('setCollegePlacementOfficer', response.data.data);
        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  addCollegePlacementOfficer({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/college/placement_officer/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  updateCollegePlacementOfficer({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.patch(`/api/v1/college/placement_officer/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
  deleteCollegeFaculty({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.delete(`/api/v1/college/faculty/`, {data: formData});

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },

  // getFDPFeedbacks1({ commit, dispatch }, data: any) {
  //   // eslint-disable-next-line no-async-promise-executor
  //   return new Promise(async (resolve, reject) => {

  //     // commit("resetData");
  //     commit("SET_LOADING", true);
  //     try {
  //       const response = await axios.get(`/api/v1/college/courses/faculty_feedbacks/`, { params: data });
       
  //       resolve(response.data);
  //     } catch (err: any) {
  //       try {
  //         reject(err.response.data.message);
  //       } catch (e) {
  //         reject(err.message);
  //       }
  //     }
  //     commit("SET_LOADING", false);
  //   });
  // },

  // getFDPFeedbackOverviews({ commit, dispatch }, data: any) {
  //   // eslint-disable-next-line no-async-promise-executor
  //   return new Promise(async (resolve, reject) => {

  //     // commit("resetData");
  //     commit("SET_LOADING", true);
  //     try {
  //       const response = await axios.get(`/api/v1/college/courses/faculty_feedback_overview/`, { params: data });
       
  //       resolve(response.data);
  //     } catch (err: any) {
  //       try {
  //         reject(err.response.data.message);
  //       } catch (e) {
  //         reject(err.message);
  //       }
  //     }
  //     commit("SET_LOADING", false);
  //   });
  // },

  sendPOInvite({ commit, dispatch }, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(data).forEach((key: string) => {
        formData.append(key, data[key]);
      });
      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`api/v1/college/sending_mail_to_po/`, formData);

        resolve(response.data);
      } catch (err: any) {
        try {
          reject(err.response.data.message);
        } catch (e) {
          reject(err.message);
        }
      }
      commit("SET_LOADING", false);
    });
  },
};
const collegePlacementOfficerStore: Module<any, any> = {
  state: () => ({
    collegePlacementOfficer: [],
  }),
  mutations: {
    setCollegePlacementOfficer(state, data: any) {
      state.collegePlacementOfficer = data;
    },
  },
  actions,
  getters: {
    collegePlacementOfficer(state) {
        return state.collegePlacementOfficer;
    }
  },
};

export default collegePlacementOfficerStore;
