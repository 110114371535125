import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {

    getFinancialYearData({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('financial_years')) {
                // @ts-ignore
                commit('saveFinancialYears', JSON.parse(localStorage.getItem('financial_years')))
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/financial-year/`);
                    commit('saveFinancialYears', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    checkUsername({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get("/api/v1/data/check-username/", {
                    params: data,
                });
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    getRegistrationDropDowns({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('management_types')) {
                // @ts-ignore
                commit('saveManagementTypes', JSON.parse(localStorage.getItem('management_types')));
                // @ts-ignore
                commit('saveCollegeTypes', JSON.parse(localStorage.getItem('college_types')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/registration-dropdowns/`);
                    commit('saveManagementTypes', response.data.management_types);
                    commit('saveCollegeTypes', response.data.college_types);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getAffiliatedUniversities({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('affiliatedUniversities')) {
                // @ts-ignore
                commit('saveAffiliatedUniversities', JSON.parse(localStorage.getItem('affiliatedUniversities')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/affiliated-universities/`);
                    commit('saveAffiliatedUniversities', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            
            commit("SET_LOADING", false);
        });
    },
    getDistrictsData({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('districts')) {
                // @ts-ignore
                commit('saveDistricts', JSON.parse(localStorage.getItem('districts')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/districts/`);
                    commit('saveDistricts', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getPassedOutYear({ commit }) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/student/student/year_of_passout`);
                commit('savePassedOutYear', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },

    // Check- Same academic year API endpoint exists for Faculty Date Change
    getAcademicYear({ commit }) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/student/student/academic_year`);
                commit('saveAcademicYear', response.data);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getZonesData({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('zones')) {
                // @ts-ignore
                commit('saveZones', JSON.parse(localStorage.getItem('zones')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/zones`);
                    commit('saveZones', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getCategoriesData({commit, dispatch}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('categories')) {
                // @ts-ignore
                commit('saveCategories', JSON.parse(localStorage.getItem('categories')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/categories`);
                    commit('saveCategories', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getDropDownCollegesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        //
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('colleges')) {
                // @ts-ignore
                commit('saveColleges', JSON.parse(localStorage.getItem('colleges')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/college/dropdown-list/`);
                    commit('saveColleges', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getStateTownFromPinCode({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        //
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(`https://api.postalpincode.in/pincode/${data}`);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    getDropDownPublicCollegesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            if (localStorage.getItem('colleges')) {
                // @ts-ignore
                commit('saveColleges', JSON.parse(localStorage.getItem('colleges')));
            } else {
                try {
                    const response = await axios.get(`/api/v1/data/colleges/list/`);
                    commit('saveColleges', response.data);
                    resolve(response.data);
                } catch (err: any) {
                    try {
                        reject(err.response.data.message);
                    } catch (e) {
                        reject(err.message);
                    }
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getDropDownBranchesList({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/branches`, { params: data });
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getPortalAnnouncements({commit, dispatch}, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/data/portal/announcements/list/`, { params: data });
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const commonStore: Module<any, any> = {
    state: () => ({
        managementTypes: {},
        collegeTypes: {},
        affiliatedUniversities: [],
        districts: [],
        zones: [],
        categories: [],
        colleges: [],
        finacialYears:[],
    }),
    mutations: {
        saveManagementTypes(state, data: any) {
            localStorage.setItem("management_types", JSON.stringify(data));
            state.managementTypes = data;
        },
        saveCollegeTypes(state, data: any) {
            localStorage.setItem("college_types", JSON.stringify(data));
            state.collegeTypes = data;
        },
        saveAffiliatedUniversities(state, data: any) {
            localStorage.setItem("affiliatedUniversities", JSON.stringify(data));
            state.affiliatedUniversities = data;
        },
        saveDistricts(state, data: any) {
            localStorage.setItem("districts", JSON.stringify(data));
            state.districts = data;
        },
        saveZones(state, data: any) {
            localStorage.setItem("zones", JSON.stringify(data));
            state.zones = data;
        },
        saveCategories(state, data: any) {
            localStorage.setItem("categories", JSON.stringify(data));
            state.categories = data;
        },
        saveColleges(state, data: any) {
            localStorage.setItem("colleges", JSON.stringify(data));
            state.colleges = data.colleges_list;
        },
        saveFinancialYears(state, data: any) {
            localStorage.setItem("financial_years", JSON.stringify(data));
            state.financialYears = data;
        },
        savePassedOutYear(state, data: any) {
            state.yearOfPassOut = data.data;
        },
        saveAcademicYear(state, data: any) {
            state.yearOfAcademic = data.data;
        }
    },
    actions,
    getters: {
        managementTypes(state) {
            return state.managementTypes;
        },
        collegeTypes(state) {
            return state.collegeTypes;
        },
        affiliatedUniversities(state) {
            return state.affiliatedUniversities;
        },
        districts(state) {
            return state.districts;
        },
        zones(state) {
            return state.zones;
        },
        categories(state) {
            return state.categories;
        },
        colleges(state) {
            return state.colleges;
        },
        financialYears(state) {
            return state.financialYears;
        },
        yearOfPassOut(state) {
            return state.yearOfPassOut
        },
        yearOfAcademic(state) {
            return state.yearOfAcademic
        },
    },
};

export default commonStore;
