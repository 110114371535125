import axios from "@/http/http";
import {Module, ActionTree} from "vuex";
import * as qs from "qs";

const actions: ActionTree<any, any> = {
    getCategoryCourses({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {

            commit("SET_LOADING", true);
            try {
                const response = await axios.get(`/api/v1/simple_courses/courses/`);
                commit('setCategoryCourses', response.data)
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
    getLMSCourseURL({ commit, dispatch }, data: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });

            commit("SET_LOADING", true);
            try {
                const response = await axios.post(`/api/v1/simple/course/launch/url/`, formData);
                resolve(response.data);
            } catch (err: any) {
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
            commit("SET_LOADING", false);
        });
    },
};
const dashboardStore: Module<any, any> = {
    state: () => ({
        categoryCourses: [],
    }),
    mutations: {
        setCategoryCourses(state, data) {

            const courses1: any = []
            data.forEach((course : any) => {
               if(course.category == 'Programming') {
                   courses1.splice(0, 0, course)
               } else if(course.category == 'Competitive Exams') {
                   courses1.splice(0, 0, course)
               } else {
                   courses1.push(course)
               }
            });
            state.categoryCourses = courses1;
        },
    },
    actions,
    getters: {
        categoryCourses(state) {
            return state.categoryCourses;
        },
    },
};

export default dashboardStore;
